import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import RoundedIconButton from '@atoms/RoundedIconButton/RoundedIconButton';
import { CarouselButtonsProps } from '@molecules/CarouselActions/CarouselButtons.props';

const CarouselButtons = (props: CarouselButtonsProps) => {
  const { emblaApi, scrollSnapsLength, colorVariant, selectedIndex, sx } = props;

  return (
    <Box
      position={{ xs: 'static', lg: 'absolute' }}
      sx={{
        mt: { xs: 40, lg: 0 },
        bottom: '50%',
        right: 24,
        ...sx,
      }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={12}
    >
      <Stack spacing={12} direction={{ xs: 'row-reverse', lg: 'column' }}>
        <RoundedIconButton
          onClick={() => emblaApi.scrollNext()}
          disabled={selectedIndex === scrollSnapsLength - 1}
          colorVariant={colorVariant}
          iconName="chevron_right"
        />
        <RoundedIconButton
          disabled={selectedIndex === 0}
          onClick={() => emblaApi.scrollPrev()}
          colorVariant={colorVariant}
          iconName="chevron_left"
        />
      </Stack>
      <Typography variant="overline">
        <Box
          component="span"
          className="font-weight-bold"
          color={colorVariant === 'light' ? 'grey.900' : 'common.white'}
        >
          {selectedIndex + 1}
        </Box>{' '}
        <Box component="span" color="grey.500">
          / {scrollSnapsLength}
        </Box>
      </Typography>
    </Box>
  );
};

export default CarouselButtons;
